<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-toolbar-title>{{
        showLoayltyBlocks ? categoryTitle : "VIP-КАТАЛОГ"
      }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu
        v-if="showLoayltyBlocks"
        transition="slide-y-transition"
        left
        bottom
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn text tile v-on="on">
            <span class="d-none d-sm-flex mr-1">Сортировка</span>
            <v-icon>$vuetify.icons.sort</v-icon>
          </v-btn>
        </template>
        <v-list class="text-right">
          <v-list-item-group color="primary">
            <!-- class="active v-list-item--active" -->
            <v-list-item
              v-for="(item, index) in sortings"
              :key="index"
              :class="item.val === sort ? 'active v-list-item--active' : ''"
              @click="sortCategory(item.val)"
            >
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-toolbar>
    <!-- <v-container v-if="!loadPage"> -->
    <banner v-if="showLoayltyBlocks" index="0"></banner>
    <book-top
      v-if="books_top && books_top.top_last_comment && showLoayltyBlocks"
      :books="books_top.top_last_comment"
      title="Обсуждаемое"
    ></book-top>
    <v-container>
      <ero-filters
        v-show="showEroFilters && showLoayltyBlocks"
        @goEroFilter="sortEroFilters($event)"
        @startEroFilter="erofilters = $event"
      ></ero-filters>
      <filters
        v-show="showLoayltyBlocks"
        @goPriceFilter="sortPriceFilters($event)"
        @startPriceFilter="pricefilters = $event"
      ></filters>

      <v-card
        v-if="!showLoayltyBlocks && currentUser && currentUser.vip"
        class="loyalty-action mb-4"
      >
        <v-card-text class="text-center">
          На все книги КАТАЛОГА для Вас сейчас действует специальная СКИДКА.
          Скидочные книги, при их наличии, также отображены и на страницах
          авторов.
        </v-card-text>
      </v-card>
      <v-row align="center">
        <no-content
          v-if="books.length == 0"
          route-name
          title="Произведений нет"
        ></no-content>

        <book-card
          v-for="(book, index) in books"
          :key="book.id"
          :book="book"
          :index="index"
          :library="false"
          :admin="false"
          :cabinet="false"
        ></book-card>
      </v-row>
      <v-row align="center">
        <v-col cols="12">
          <v-pagination
            v-if="pages > 1"
            @input="goPage"
            v-model="page"
            :length="pages"
            :total-visible="7"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>
    <book-top
      v-if="books_top && books_top.top_update"
      :books="books_top.top_update"
      title="Обновления книг"
    ></book-top>
    <banner index="1"></banner>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import BookCard from "./BookCard.vue";
import BookTop from "./BookTop.vue";
import EroFilters from "./EroFilters.vue";
import Filters from "./Filters.vue";
import Banner from "../Banner.vue";
export default {
  components: {
    BookCard,
    BookTop,
    Filters,
    EroFilters,
    Banner,
  },
  data: () => ({
    bookSlug: "",
    sort: "top",
    sortings: [
      { text: "По активности", val: "last_comment" },
      { text: "По новизне", val: "new" },
      // { text: "По популярности", val: "popular" },
      { text: "По популярности", val: "top" },
      { text: "По комментариям", val: "comments" },
    ],
    page: 1,
    pages: 0,
    loading: false,
    categoryTitle: "",
    type: "category",
    // fPrice: null,
    // fComplete: null,
    erofilters: null,
    pricefilters: null,
  }),
  metaInfo() {
    if (this.categoryTitle) {
      return {
        title: `Читать ${this.categoryTitle} книги онлайн бесплатно - Bookstab`,
        meta: [
          {
            name: "description",
            content:
              "Лучшие книги в жанре " +
              this.categoryTitle +
              " читать онлайн бесплатно на Bookstab. Скачать книги в жанре " +
              this.categoryTitle +
              " в формате fb2 c электронной библиотеки без регистрации",
          },
          {
            property: "og:title",
            content:
              "Читать" +
              this.categoryTitle +
              "книги онлайн бесплатно - Bookstab",
          },
          { property: "og:site_name", content: "Bookstab" },
          {
            property: "og:description",
            content:
              "Лучшие книги читать онлайн бесплатно на Bookstab. Скачать книги в жанре " +
              this.categoryTitle +
              " в формате fb2 c электронной библиотеки без регистрации",
          },
          { property: "og:type", content: "category" },
          { property: "og:url", content: window.location.href },
        ],
      };
    }
    return {};
  },
  methods: {
    getBooks() {
      var app = this;
      let data = {
        bookSlug: app.bookSlug,
        page: app.page,
        sort: app.sort,
        type: app.type,
      };
      if (this.erofilters) {
        data.erofilters = this.erofilters;
      }
      if (this.pricefilters) {
        data.pricefilters = this.pricefilters;
      }

      this.$store.dispatch("getBooks", data).then((response) => {
        // app.loading = false;
        if (response.data.success == 1) {
          app.pages = response.data.books.last_page;

          if (app.type == "tag") {
            app.categoryTitle = response.data.tag.name.ru;
          } else {
            app.categoryTitle = response.data.category.name;
          }
        }
      });
    },
    goPage() {
      this.goSort();
    },
    sortCategory(type) {
      if (type != this.sort) {
        this.sort = type;
        this.goSort();
      }
    },
    goSort() {
      // console.log(JSON.stringify(this.$route.query))
      // console.log(JSON.stringify(this.sortQuery()))
      // console.log(JSON.stringify(this.$route.query) !== JSON.stringify(this.sortQuery()))

      if (
        JSON.stringify(this.$route.query) !== JSON.stringify(this.sortQuery())
      ) {
        this.$router.push({
          name: this.$route.name,
          params: { slug: this.bookSlug },
          query: this.sortQuery(),
        });
      }
    },
    sortQuery() {
      let query = {
        page: this.page + "",
        sort: this.sort,
      };
      // if (this.fPrice) {
      //   query.fPrice = this.fPrice;
      // }
      // if (this.fComplete) {
      //   query.fComplete = this.fComplete;
      // }
      if (this.erofilters) {
        query = Object.assign(query, this.erofilters);
      }
      if (this.pricefilters) {
        query = Object.assign(query, this.pricefilters);
      }

      return query;
    },
    sortEroFilters(data) {
      this.erofilters = data;
      this.goSort();
    },
    sortPriceFilters(data) {
      this.pricefilters = data;
      this.goSort();
    },
  },
  computed: {
    ...mapGetters({
      loadPage: "loadPage",
      books: "books",
      category: "category",
      books_top: "books_top",
      currentUser: "currentUser",
    }),
    showEroFilters() {
      // let arrCats = [1,2,3,4,5,6,35,36,37,38,39,40];
      // if(this.category && arrCats.includes(+this.category.id)){
      //   return true;
      // }

      return true;
    },
    showLoayltyBlocks() {
      if (this.sort && this.sort == "loyalty") {
        return false;
      }

      return true;
    },
  },
  mounted() {
    //console.log(this.$route.query.page);
    this.bookSlug = this.$route.params.slug ? this.$route.params.slug : "all";
    this.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
    this.sort = this.$route.query.sort ? this.$route.query.sort : "top";
    if (this.$route.name == "TagShow") {
      this.type = "tag";
    }
    // this.fPrice = this.$route.query.fPrice ? this.$route.query.fPrice : null;
    // this.fComplete = this.$route.query.fComplete
    //   ? this.$route.query.fComplete
    //   : null;
    //  console.log("getBooks")
    this.getBooks();

    // this.$nextTick(() => {
    //   console.log("start book")
    //   this.getBooks();
    // })
  },
};
</script>
