<template>
  <v-card class="mb-4 filter-tabs">
    <v-menu
      v-if="$vuetify.breakpoint.smAndDown"
      transition="slide-y-transition"
      left
      bottom
      offset-y
    >
      <template v-slot:activator="{ on }">
        <v-btn text tile v-on="on">
          <span class="mr-1">Фильтры</span>
          <v-icon>$vuetify.icons.sort</v-icon>
        </v-btn>
      </template>
      <v-list class="text-right">
        <v-list-item-group color="primary">
          <!-- class="active v-list-item--active" -->
          <!-- :class="item.val === 'all'  || item.val == 'paid' ? 'active v-list-item--active' : ''" -->
          <v-list-item
            v-for="(item, index) in filters"
            :key="index"
            :class="
              (fPrice === item.val && item.type == 'price') ||
              (fComplete === item.val && item.type == 'complete') ||
              (item.val == 'all' && !fPrice && !fComplete)
                ? 'active v-list-item--active'
                : ''
            "
            @click="changeFilter(item.val, item.type)"
          >
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-toolbar v-if="$vuetify.breakpoint.mdAndUp" class="elevation-0">
      <v-toolbar-title>Фильтры</v-toolbar-title>
    </v-toolbar>
    <div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex px-4 pb-4">
      <v-btn
        v-for="(item, index) in filters"
        :key="index"
        small
        :color="
          (fPrice === item.val && item.type == 'price') ||
          (fComplete === item.val && item.type == 'complete') ||
          (item.val == 'all' && !fPrice && !fComplete)
            ? 'primary'
            : ''
        "
        class="mr-2"
        @click="changeFilter(item.val, item.type)"
      >
        {{ item.text }}</v-btn
      >
    </div>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    fPrice: null,
    fComplete: null,
    filters: [
      { text: "Все", val: "all", type: "all" },
      { text: "Бесплатные", val: "free", type: "price" },
      { text: "Платные", val: "paid", type: "price" },
      { text: "В процессе", val: "work", type: "complete" },
      { text: "Завершенные", val: "finish", type: "complete" },
    ],
  }),
  methods: {
    clearFilter() {
      this.fPrice = null;
      this.fComplete = null;
      this.goFilter();
    },
    changePrice(filter) {
      this.fPrice = filter;
      this.goFilter();
    },
    changeComplete(filter) {
      this.fComplete = filter;
      this.goFilter();
    },
    changeFilter(value, type) {
      if (type == "all") {
        this.fPrice = null;
        this.fComplete = null;
      } else if (type == "price") {
        this.fPrice = value;
      } else if (type == "complete") {
        this.fComplete = value;
      }

      this.goFilter();
    },
    goFilter() {
      this.$emit("goPriceFilter", this.sortQuery()); 
      // this.$router.push({
      //   name: this.$route.name,
      //   params: { slug: this.catSlug },
      //   query: this.sortQuery(),
      // });
    },
    sortQuery() {
      let query = {
      };
      if (this.fPrice) {
        query.fPrice = this.fPrice;
      }
      if (this.fComplete) {
        query.fComplete = this.fComplete;
      }

      return query;
    },
  },
  computed: {
    ...mapGetters(["loadPage", "books"]),
  },
  mounted() {
    this.fPrice = this.$route.query.fPrice ? this.$route.query.fPrice : null;
    this.fComplete = this.$route.query.fComplete
      ? this.$route.query.fComplete
      : null;

    // console.log("startPriceFilter")
    this.$emit("startPriceFilter", this.sortQuery());  
  },
};
</script>