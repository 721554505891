<template>
  <v-card class="mb-4 pa-4 filter-tabs" v-if="allEroFilters">
    <v-btn dark color="primary" @click="showFilters = !showFilters"
      >Дополнительный поиск</v-btn
    >
    <v-slide-y-transition>
      <div v-show="showFilters">
        <ero-filter
          @checkFilter="addParam($event, 'eroContact')"
          :filter="allEroFilters.eroContact"
          name="eroContact"
          type="radio"
        >
        </ero-filter>
        <ero-filter
          @checkFilter="addParam($event, 'contact')"
          :filter="allEroFilters.contact"
          name="contact"
          type="radio"
        >
        </ero-filter>
        <ero-filter
          @checkFilter="addParam($event, 'ageLovers')"
          :filter="allEroFilters.ageLovers"
          name="ageLovers"
          type="radio"
        >
        </ero-filter>
        <ero-filter
          @checkFilter="addParam($event, 'natureContact')"
          :filter="allEroFilters.natureContact"
          name="natureContact"
          type="checkbox"
          max="3"
        >
        </ero-filter>

        <ero-filter
          @checkFilter="addParam($event, 'heroType')"
          :filter="allEroFilters.heroType"
          name="heroType"
          type="radio"
        >
        </ero-filter>

        <ero-filter
          @checkFilter="addParam($event, 'socialStatus')"
          :filter="allEroFilters.socialStatus"
          name="socialStatus"
          type="radio"
        >
        </ero-filter>

        <ero-filter
          @checkFilter="addParam($event, 'ageHero')"
          :filter="allEroFilters.ageHero"
          name="ageHero"
          type="radio"
        >
        </ero-filter>

        <ero-filter
          @checkFilter="addParam($event, 'heroineType')"
          :filter="allEroFilters.heroineType"
          name="heroineType"
          type="checkbox"
          max="2"
        >
        </ero-filter>
        <ero-filter
          @checkFilter="addParam($event, 'ageHeroine')"
          :filter="allEroFilters.ageHeroine"
          name="ageHeroine"
          type="radio"
        >
        </ero-filter>
        <ero-filter
          @checkFilter="addParam($event, 'heroineinPosition')"
          :filter="allEroFilters.heroineinPosition"
          name="heroineinPosition"
          type="radio"
        >
        </ero-filter>
        <ero-filter
          @checkFilter="addParam($event, 'heroWealth')"
          :filter="allEroFilters.heroWealth"
          name="heroWealth"
          type="radio"
        >
        </ero-filter>
        <ero-filter
          @checkFilter="addParam($event, 'heroineWealth')"
          :filter="allEroFilters.heroineWealth"
          name="heroineWealth"
          type="radio"
        >
        </ero-filter>

        <v-btn dark color="primary" @click="goFilter">Поиск</v-btn>
      </div>
    </v-slide-y-transition>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import EroFilter from "./EroFilter.vue";
export default {
  components: { EroFilter },
  data: () => ({
    query: {},
    showFilters: false,
  }),
  methods: {
    goFilter() {
      // console.log(this.sortQuery());
      this.$emit("goEroFilter", this.query);
    },
    addParam(data, name) {
      
      if (data && data.length > 0) {
        this.query[name] = data;
      }
      else{
        delete this.query[name];
      }
      // console.log(this.query);
    },
    getStartQuery() {
      let query = {};
      let eroContact = this.getQueryArray("eroContact");
      if (eroContact) query = Object.assign(query, { eroContact: eroContact });

      let contact = this.getQueryArray("contact");
      if (contact) query = Object.assign(query, { contact: contact });

      let ageLovers = this.getQueryArray("ageLovers");
      if (ageLovers) query = Object.assign(query, { ageLovers: ageLovers });

      let natureContact = this.getQueryArray("natureContact");
      if (natureContact)
        query = Object.assign(query, { natureContact: natureContact });

      let heroType = this.getQueryArray("heroType");
      if (heroType) query = Object.assign(query, { heroType: heroType });

      let socialStatus = this.getQueryArray("socialStatus");
      if (socialStatus)
        query = Object.assign(query, { socialStatus: socialStatus });

      let ageHero = this.getQueryArray("ageHero");
      if (ageHero) query = Object.assign(query, { ageHero: ageHero });

      let heroineType = this.getQueryArray("heroineType");
      if (heroineType)
        query = Object.assign(query, { heroineType: heroineType });

      let ageHeroine = this.getQueryArray("ageHeroine");
      if (ageHeroine) query = Object.assign(query, { ageHeroine: ageHeroine });

      let heroineinPosition = this.getQueryArray("heroineinPosition");
      if (heroineinPosition)
        query = Object.assign(query, { heroineinPosition: heroineinPosition });

      let heroWealth = this.getQueryArray("heroWealth");
      if (heroWealth) query = Object.assign(query, { heroWealth: heroWealth });

      let heroineWealth = this.getQueryArray("heroineWealth");
      if (heroineWealth)
        query = Object.assign(query, { heroineWealth: heroineWealth });

      // console.log(query);
      this.$emit("startEroFilter", query);
    },
    getQueryArray(name) {
      if (this.$route.query[name]) {
        // if (!Array.isArray(this.$route.query[name])) {
        //   return [this.$route.query[name]];
        // } else {
        return this.$route.query[name];
        // }
      }
      return null;
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      allEroFilters: "allEroFilters",
      siteSettings: "siteSettings",

      books: "books",
    }),
    // allEroFilters(){
    //   if(this.siteSettings && this.siteSettings.allEroFilters && this.siteSettings.allEroFilters.length > 0){
    //     return this.siteSettings.allEroFilters;
    //   }
    //   return [];
    // }
  },
  mounted() {
    this.getStartQuery();
  },
};
</script>