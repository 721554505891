var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-4 filter-tabs"},[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-menu',{attrs:{"transition":"slide-y-transition","left":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","tile":""}},on),[_c('span',{staticClass:"mr-1"},[_vm._v("Фильтры")]),_c('v-icon',[_vm._v("$vuetify.icons.sort")])],1)]}}],null,false,232978340)},[_c('v-list',{staticClass:"text-right"},[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.filters),function(item,index){return _c('v-list-item',{key:index,class:(_vm.fPrice === item.val && item.type == 'price') ||
            (_vm.fComplete === item.val && item.type == 'complete') ||
            (item.val == 'all' && !_vm.fPrice && !_vm.fComplete)
              ? 'active v-list-item--active'
              : '',on:{"click":function($event){return _vm.changeFilter(item.val, item.type)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-toolbar',{staticClass:"elevation-0"},[_c('v-toolbar-title',[_vm._v("Фильтры")])],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"d-flex px-4 pb-4"},_vm._l((_vm.filters),function(item,index){return _c('v-btn',{key:index,staticClass:"mr-2",attrs:{"small":"","color":(_vm.fPrice === item.val && item.type == 'price') ||
        (_vm.fComplete === item.val && item.type == 'complete') ||
        (item.val == 'all' && !_vm.fPrice && !_vm.fComplete)
          ? 'primary'
          : ''},on:{"click":function($event){return _vm.changeFilter(item.val, item.type)}}},[_vm._v(" "+_vm._s(item.text))])}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }