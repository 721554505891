<template>
  <div>
    <div
      v-if="type =='checkbox' &&  filter && filter.items"
      class="dop-filters-group"
    >
      <p class="flter-header">
        {{ filter.header }}
        <span class="primary--text" @click="clearFilter">[очистить]</span>
      </p>
      <v-checkbox
        v-model="value"
        v-for="(item, index) in eroItemsCh(filter.items)"
        :key="index"
        :index="index"
        :label="item.label"
        :value="item.value"
        :disabled="filterDisabled(item.value)"
      ></v-checkbox>
    </div>
    
    <v-radio-group
      class="dop-filters-group"
      v-if="type =='radio' && filter && filter.items"
      v-model="value"
    >
      <p class="flter-header">
        {{ filter.header }}
        <span class="primary--text" @click="clearFilter">[очистить]</span
        >
      </p>
      <v-radio
        v-for="(item, index) in eroItemsCh(filter.items)"
        :key="index"
        :index="index"
        :label="item.label"
        :value="item.value"
      ></v-radio>
    </v-radio-group>
  </div>
</template>
<script>
export default {
  props: ["filter", "name", "type", "max"],
  data() {
    return {
      value: this.type == 'checkbox' ? [] :null
    };
  },
  methods: {
    clearFilter() {
      if(this.type == 'checkbox'){
        this.value = [];
      }
      else{
        this.value = null;
      }
    },
    eroItemsCh(items) {
      let selectItems = [];
      for (var key in items) {
        // let obj = {"value": name+'_'+key, "title":items[key]};
        let k = this.name + "_" + key;
        let label = items[key];
        let obj = { value: k, label: label };
        selectItems.push(obj);
      }
      // console.log(selectItems);
      return selectItems;
    },
    filterDisabled(val) {
      if (this.max && this.value.length >= this.max) {
        if (!this.value.includes(val)) {
          return true;
        }
      }
      return false;
    },
    getQueryArray() {
      if (this.$route.query[this.name]) {
        if (!Array.isArray(this.$route.query[this.name]) && this.type == 'checkbox') {
          return [this.$route.query[this.name]];
        } else {
          return this.$route.query[this.name];
        }
        
      }
      return [];
    },
  },
  watch:{
    value(){
      this.$emit("checkFilter", this.value); 
    }
  },
  mounted() {
    this.value = this.getQueryArray();
  }
};
</script>